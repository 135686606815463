import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getDoctorsByClient } from '../../actions/doctor'
import { login } from '../../actions/user'

import Footer from '../../components/Footer/Footer'
import PasswordInput from '../../components/inputs/PasswordInput/PasswordInput'
import { useTitle } from '../../helpers/use_title.helper'
import './Login.css'

function Login () {
  useTitle('Ki | Login')

  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)

  const dispatch = useDispatch()
  const history = useNavigate()
  const [newUser, setNewUser] = useState({
    email: '',
    password: ''
  })
  const [visiblePassword, setVisiblePassword] = useState(false)

  useEffect(() => {
    let isActive = true
    if (isActive && user && user?._id && token) {
      history('/main')
    }
    return function cleanup () {
      isActive = false
    }
  }, [])

  function updateLocalStorage (data, objToUpdate, type) {
    window.localStorage.setItem(objToUpdate, JSON.stringify(data))
    dispatch({
      type,
      payload: data
    })
  }

  function handleChange (event) {
    const { name, value } = event.target

    setNewUser((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  function togglePasswordVisiblity () {
    setVisiblePassword(!visiblePassword)
  }

  async function handleSubmit (e) {
    e.preventDefault()
    try {
      const res = await login(newUser)
      if (res.data) {
        const { user: u, token, client } = res.data
        updateLocalStorage(u, 'user', 'LOGGED_IN_USER')
        updateLocalStorage(token, 'token', 'LOGGED_IN_TOKEN')
        updateLocalStorage(client, 'client', 'LOGGED_IN_CLIENT')
        // Get doctors for this user
        const { data: doctors } = await getDoctorsByClient(u, client._id, token)
        if (doctors) updateLocalStorage(doctors, 'doctors', 'NEW_DOCTORS')
        history('/main')
      }
    } catch (error) {
      if (error.response.status === 403 || error.response.status === 404) toast.error(error.response.data.message)
      if (error.response.status === 400) toast.error(error.response.data.error || error.response.data.message)
    }
  }

  return (
    <div className='login'>
      <div className='login-container container-fluid'>
        <form className='form login-form' onSubmit={handleSubmit}>
          <div className='form-title'>
            <h2>Iniciar Sesión</h2>
            <p>
              ¿No estas registrado?
              <Link
                className='login-link text-nowrap'
                to='/register'
              >
                {' '}Crea una cuenta
              </Link>
            </p>
          </div>
          <div className='form-floating'>
            <input
              name='email'
              type='email'
              className='form-control'
              id='floatingEmail'
              placeholder='Correo Electrónico'
              value={newUser.email}
              onChange={handleChange}
              autoComplete='username'
              required
            />
            <label htmlFor='floatingEmail'>Correo Electrónico</label>
          </div>
          <PasswordInput
            visiblePassword={visiblePassword}
            togglePasswordVisiblity={togglePasswordVisiblity}
            handleChange={handleChange}
            value={newUser.password}
            name='password'
            text='Contraseña'
          />
          <button type='submit' className='btn main-btn'>Iniciar Sesión</button>
          <p>
            <Link
              className='password-link text-nowrap'
              to='/password-recovery'
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </p>
        </form>

        <Footer />
      </div>
    </div>
  )
}

export default Login
