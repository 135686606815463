import { useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { cutText, formatDate } from '../../../helpers/style_string.helper'
import CalendarPicker from '../../pickers/CalendarPicker/CalendarPicker'
import CampaignPicker from '../../pickers/CampaignPicker/CampaignPicker'
import DownloadTemplateBtn from '../../buttons/DownloadTemplateBtn/DownloadTemplateBtn'
import LoadingBtn from '../../buttons/LoadingBtn/LoadingBtn'
import { getDatesWithCampaign } from '../../../actions/confirmation'
import './MainSteps.css'

function MainSteps ({
  value,
  text,
  btnTxt,
  icon,
  fileName,
  setFileName,
  handleFileChange,
  handleDBSearch,
  loadingTable,
  searchDate,
  setSearchDate,
  setDateForDB,
  campaignState,
  setCampaignState,
  campaignState2,
  setCampaignState2
}) {
  const client = useSelector((state) => state.client)
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)

  const [campaignDates, setCapaignDates] = useState([])

  async function getDatesForSelectedMonth (date = '', campaign = campaignState2) {
    // Make a request to the API to get the dates for the selected month
    try {
      const res = await getDatesWithCampaign(user, token, campaign, client._id, date)
      if (res.data && res.data.length > 0) {
        const dates = res.data
        const lastDate = new Date(dates[dates.length - 1] + 'T05:00:00.000Z')
        setSearchDate(lastDate)
        setDateForDB(formatDate(lastDate))
        setCapaignDates(dates)
      } else {
        toast.warning('No se encontraron campañas para la fecha seleccionada.')
      }
    } catch (error) {
      toast.error('Ocurrió un error al cargar la tabla.')
    }
  }

  return (
    <div className='col-md-12 step'>
      {btnTxt === 'Subir archivo' && <DownloadTemplateBtn />}
      <div className='step-circle'>
        <p className='step-num'>{value}</p>
      </div>
      {text}
      {btnTxt === 'Subir archivo' && (
        <>
          <CampaignPicker
            campaignState={campaignState}
            setCampaignState={setCampaignState}
            resetCampaing={setCampaignState2}
            btnTxt={btnTxt}
          />
          {fileName !== '' &&
            <div className='drag-drop-container form-control'>
              <div className='center'>
                <p className='file-name'>{cutText(fileName, true, 20)}</p>
              </div>
            </div>}
          <label
            className={campaignState !== 'marketing' ? 'btn main-btn' : 'btn main-btn disabled'}
            disabled={campaignState === 'marketing'}
          >
            <div className='row'>
              <div className='col-2'><i className={icon} /></div>
              <div className='col-10'>{btnTxt}</div>
            </div>
            {campaignState === ''
              ? <input
                  value=''
                  onClick={() => toast.error('Selecciona una campaña.')}
                  hidden
                  readOnly
                />
              : <input
                  value=''
                  type='file'
                  name='excel-file'
                  onChange={handleFileChange}
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  hidden
                  disabled={campaignState === 'marketing'}
                />}
          </label>
        </>
      )}
      {btnTxt === 'Buscar' && (
        <div>
          <CampaignPicker
            campaignState={campaignState2}
            setCampaignState={setCampaignState2}
            resetCampaing={setCampaignState}
            btnTxt={btnTxt}
            setCapaignDates={setCapaignDates}
            setSearchDate={setSearchDate}
            setDateForDB={setDateForDB}
            getDatesForSelectedMonth={getDatesForSelectedMonth}
          />
          <CalendarPicker
            searchDate={searchDate} setSearchDate={setSearchDate} setDateForDB={setDateForDB}
            campaignDates={campaignDates}
            getDatesForSelectedMonth={getDatesForSelectedMonth}
          />
          {!loadingTable
            ? (
              <button
                disabled={loadingTable === true}
                className='main-btn'
                onClick={handleDBSearch}
                value={value}
              >
                <div className='row main-btn-content'>
                  <div className='col-2'><i className={icon} /></div>
                  <div className='col-10'>{btnTxt}</div>
                </div>
              </button>)
            : <LoadingBtn isLoading={loadingTable} text='Cargando...' />}
        </div>
      )}
    </div>
  )
}

export default MainSteps
