export const wpStatus = Object.freeze({
  STORED: 'Almacenado',
  ERROR: 'Error',
  SENT: 'Enviado',
  CALL: 'Contactar',
  NO_REPLY: 'Ignorado',
  OTHER: 'Escritura',
  YES: 'Confirmado',
  NO: 'Cancelado',
  NOT_ATTENDED: 'No asistió',
  PAYMENT: 'Pago',
  RESCHEDULED: 'Reagendado',
  NO_ANSWER: 'No contestó'
})

export const wpStatus6Month = Object.freeze({
  STORED: 'Almacenado',
  ERROR: 'Error',
  SENT: 'Enviado',
  CALL: 'Contactar',
  NO_REPLY: 'Ignorado',
  OTHER: 'Escritura',
  YES: 'Agendado',
  NO: 'No contactar',
  NO_ANSWER: 'No contestó'
})
