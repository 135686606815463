import { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import { formatDate } from '../../../helpers/style_string.helper'
import './CalendarPicker.css'

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css'
registerLocale('es', es)

function CalendarPicker ({ searchDate, setSearchDate, setDateForDB, campaignDates, getDatesForSelectedMonth }) {
  const [selectedMonth, setSelectedMonth] = useState(new Date())

  useEffect(() => {
    // If the selected month is different from the search date, update the search date
    if (selectedMonth.getMonth() !== searchDate.getMonth()) {
      // Make a request to the API to get the dates for the selected month
      getDatesForSelectedMonth(selectedMonth.toISOString().split('T')[0])
    }
  }, [selectedMonth])

  function blockDates (date) {
    if (campaignDates.includes(formatDate(date))) return true
  }

  return (
    <DatePicker
      dateFormat='dd/MM/yyyy'
      className='form-control date-picker'
      selected={searchDate}
      onChange={(date) => {
        setSearchDate(date)
        setDateForDB(formatDate(date))
      }}
      locale='es'
      filterDate={blockDates}
      onKeyDown={(e) => {
        e.preventDefault()
      }}
      disabled={campaignDates.length === 0}
      onMonthChange={(date) => {
        setSelectedMonth(date)
      }}
    />
  )
}

export default CalendarPicker
