import React from 'react'
import { useSelector } from 'react-redux'

import { campaigns } from '../../../constants/campaigns'
import clients from '../../../constants/clients'

function CampaignPicker ({
  campaignState,
  setCampaignState,
  resetCampaing,
  btnTxt,
  setCapaignDates,
  setSearchDate,
  setDateForDB,
  getDatesForSelectedMonth
}) {
  const client = useSelector((state) => state.client)

  function renderOptionsFromClient () {
    let campaign = campaigns[client._id]
    if (!campaign) campaign = campaigns[clients.DEFAULT]
    return campaign.map((c, i) => {
      return (
        <option key={i + c.value} value={c.value}>{c.text}</option>
      )
    })
  }

  async function handleSelectorChange (e) {
    setCampaignState(e.target.value)
    resetCampaing('')
    if (btnTxt === 'Buscar') {
      await getDatesForSelectedMonth('', e.target.value) // '' means no month is selected yet
    }
  }

  return (
    <select
      className='form-control form-select'
      value={campaignState}
      onChange={handleSelectorChange}
    >
      <option value=''>Seleccionar campaña...</option>

      {client && renderOptionsFromClient()}
    </select>
  )
}

export default CampaignPicker
