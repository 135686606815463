import parse from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal } from 'bootstrap'
// import _ from 'lodash'
import BaseTable from '../BaseTable/BaseTable'
import LoadingBtn from '../../buttons/LoadingBtn/LoadingBtn'
import UserCounterPopover from '../../modals/UserCounterPopover/UserCounterPopover'
import Results from '../../components/Results/Results'
import WpStatePicker from '../../pickers/WpStatePicker/WpStatePicker'
import { sendMessages } from '../../../actions/confirmation'
import { wpStatus, wpStatus6Month } from '../../../constants/wpStatus'
import { jsonToCsv } from '../../../helpers/excel_json.helper'
import { formatDate, formatTimeToMilitary } from '../../../helpers/style_string.helper'
import { countResponsesStatus, countResponseStatus, formatRowStatus } from '../../../helpers/table.helper'
import SendConfirmationModal from '../../modals/SendConfirmationModal/SendConfirmationModal'
import { campaigns } from '../../../constants/campaigns'
import './CampaignTable.css'

function CampaignTable ({
  json,
  header,
  tableState,
  flowStates,
  isHistory,
  campaignState,
  campaignState2,
  fileName,
  onWpStateChange = () => {},
  savingMessagesStatus,
  setSavingMessagesStatus,
  updateLocalStorage,
  setTableState,
  setFlowStates,
  filter,
  resetFilters
}) {
  const location = useLocation()
  const user = useSelector((state) => state.user)
  const table = useSelector((state) => state.table)
  const client = useSelector((state) => state.client)
  const token = useSelector((state) => state.token)

  const [savingMessages, setSavingMessages] = useState(false)
  const [modalState, setModalState] = useState(null)
  const modal = useRef()
  const [tableTitle, setTableTitle] = useState('Plantilla')
  const [counterUsers, setCounterUsers] = useState([])
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    setModalState(new Modal(modal.current))
  }, [])

  useEffect(() => {
    formatCampaignName(campaignState, campaignState2)
  }, [json])

  useEffect(() => {
    if (table) {
      const confirmations = table?.confirmations?.filter(c => counterUsers.some(u => u._id === c._id))
      const campaign = campaignState !== '' ? campaignState : campaignState2
      countResponseStatus(confirmations, campaign, client._id, setFlowStates)
    }
  }, [counterUsers, table])

  function formatJsonForDB (formattedJson) {
    if (formattedJson.length > 0) {
      const formattedTimeJson = formattedJson.map(row => {
        const { date, time } = row
        const [d, m, y] = date.split('/')
        const formattedDate = y + '-' + m + '-' + d + 'T05:00:00Z'
        const r = {
          ...row,
          time: formatTimeToMilitary(time),
          date: new Date(formattedDate),
          wpStatus: '',
          status: 'STORED'
        }
        if (!row?.name) r.name = r.firstName + ' ' + r.lastName
        if (row.contact) r.contact = row.contact !== 'No'
        return r
      })

      const docName = fileName.replace('.csv', '') + ' - ' + formatDate(new Date())

      return {
        docName,
        confirmations: formattedTimeJson,
        status: 'STORED',
        campaign: campaignState,
        userId: user._id,
        clientId: client._id
      }
    }
  }

  async function sendMessagesToAPI () {
    try {
      const jsonForDB = formatJsonForDB(json)
      console.log({ table: jsonForDB, client })
      const payload = { table: jsonForDB, client }
      const res = await sendMessages(user, token, payload)
      const data = res.data
      if (data) {
        toast.success('Tus mensajes fueron enviados correctamente.')
        setSavingMessagesStatus('success')
        updateLocalStorage(data, 'table', 'NEW_TABLE')
        setTableState(data)
        // Count the confirmation states
        countResponsesStatus(data, setFlowStates)
      }
    } catch (error) {
      if (error?.response?.status === 400) toast.error(error.response.data.error || error.response.data.message)
      toast.error('Ocurrió un error al enviar tus mensajes. Revisa el archivo y vuelve a intentarlo. ' + error.message)
      setSavingMessagesStatus('error')
    }
    setSavingMessages(false)
  }

  async function startFlow () {
    if (campaignState === '') {
      return toast.error('Selecciona una campaña.')
    }
    setSavingMessages(true)
    await sendMessagesToAPI()
    modalState.hide()
  }

  function downloadCSV (json, header) {
    const { confirmations } = tableState
    const campaign = campaignState !== '' ? campaignState : campaignState2
    const formattedJson = json.map((row, i) => {
      let { status } = row
      if (row.status.includes('<select')) {
        status = formatRowStatus(confirmations[i].status, campaign, client._id)
      }
      return { ...row, status }
    })
    const b = jsonToCsv(formattedJson, header)
    // Create a blob
    // eslint-disable-next-line no-undef
    const blob = new Blob([b], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)

    // Create a link to download it
    const pom = document.createElement('a')
    pom.href = url
    pom.setAttribute('download', fileName.replace('.csv', ''))
    pom.click()
  }

  function formatRowData (rowData, row, i) {
    if (
      location.pathname === '/main' &&
      rowData !== null &&
      rowData !== undefined &&
      typeof rowData === 'string') {
      if (rowData.includes('<select') && table) {
        return parse(rowData, {
          replace: ({ attribs }) => {
            if (attribs && attribs.name === 'wp-status') {
              const rowId = attribs.id
              const foundRow = table?.confirmations?.find(c => rowId === c?._id)
              if (!foundRow) return
              const statusArr = foundRow?.status
              return (
                <WpStatePicker
                  wpStatus={table.campaign === '6 month' ? wpStatus6Month : wpStatus}
                  value={statusArr[statusArr.length - 1]}
                  onChange={async (e) => await onWpStateChange(e, foundRow._id)}
                  campaign={table.campaign}
                />
              )
            }
          }
        })
      }
    }
    return rowData
  }

  function countJsonUsers () {
    let jsonLength = 0
    const obj = counterUsers.length > 0 ? counterUsers : json
    obj.forEach((row, i) => {
      const campaign = campaignState !== '' ? campaignState : campaignState2
      const name = campaign === 'marketing' && Object.keys(row).includes('firstName') ? row?.firstName : row?.name
      const nameArr = name?.split(', ')
      jsonLength += nameArr?.length
    })
    return {
      messages: json.length,
      users: jsonLength
    }
  }

  function formatCampaignName (campaignState, campaignState2) {
    const campaign = isHistory.current ? campaignState2 : campaignState
    const clientCampaign = campaigns[client._id]
      .filter(c => c.value === campaign)[0]
      .text
      .split(' ')[0]
    setTableTitle(clientCampaign || 'Plantilla')
  }

  return (
    <>
      <SendConfirmationModal
        modal={modal}
        modalState={modalState}
        loading={savingMessages}
        campaignState={campaignState}
        startFlow={startFlow}
        jsonLength={countJsonUsers().users}
      />
      <div className='row d-flex title-row'>
        {window.outerWidth > 1028 &&
          <div className='col-md-3'>
            <h1 className='information-title'>
              {tableTitle}
            </h1>
          </div>}
        <div className='col-md-9 title-btns-container'>
          <div className='title-btns'>
            <div className='form-floating input-div'>
              <input
                name='searchText'
                type='name'
                className='form-control search-input'
                id='searchText'
                placeholder='Búsqueda'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <label className='search-label' htmlFor='searchText'>Búsqueda</label>
            </div>
            <div className='container-quantity-filter'>
              <div className='quantity-filter title-btn'>
                <UserCounterPopover
                  message='Usuarios'
                  innerContent={
                    <p>
                      <i className='fas fa-user' />
                      {countJsonUsers().users}
                    </p>
                }
                />
              </div>
            </div>
            {campaignState !== 'marketing' && campaignState2 !== 'marketing' && (
              <button
                onClick={resetFilters}
                className='btn title-btn'
              >
                <i className='fas fa-trash-alt' />
              </button>
            )}
          </div>
        </div>
      </div>
      <BaseTable
        json={json}
        header={header}
        formatRowData={formatRowData}
        filter={filter}
        confirmations={table?.confirmations}
        setCounterUsers={setCounterUsers}
        searchText={searchText}
        setSearchText={setSearchText}
        showFilters
      />
      {(isHistory.current || (tableState && savingMessagesStatus === 'success'))
        ? (
          <Results
            flowStates={flowStates}
            downloadCSV={downloadCSV}
            json={json}
            header={header}
            table={tableState}
          />
          )
        : (
          <div>
            {savingMessagesStatus === 'success' &&
              <p className='success-msg'>¡Los mensajes se enviaron correctamente!</p>}
            {savingMessagesStatus === 'error' &&
              <p className='error-msg'>Ocurrió un error al enviar tus mensajes. Revisa el archivo y vuelve a
                intentarlo.
              </p>}

            {!savingMessages
              ? (
                <button
                  disabled={savingMessages === true}
                  className='main-btn send-btn'
                  onClick={() => modalState.show()}
                >
                  <div className='row main-btn-content'>
                    <div className='col-2'><i className='fas fa-comment-dots' /></div>
                    <div className='col-10'>Enviar</div>
                  </div>
                </button>
                )
              : (<LoadingBtn isLoading={savingMessages} text='Enviando...' className='send-btn' />)}
          </div>)}
    </>
  )
}

export default CampaignTable
