import React from 'react'
import './WpStatePicker.css'

function WpStatePicker ({ value, onChange, wpStatus, campaign }) {
  function formatValue (value) {
    if (value.includes('CONV_REPLIED_CALL') && campaign === 'confirmation_dr') {
      return 'MANUAL_REPLIED_PAYMENT'
    } else if (value.includes('_REPLIED_CALL')) {
      return 'MANUAL_REPLIED_CALL'
    } else if (value.includes('_REPLIED_YES')) {
      return 'MANUAL_REPLIED_YES'
    } else if (value.includes('_REPLIED_NO')) {
      return 'MANUAL_REPLIED_NO'
    } else if (value.includes('_REPLIED_OTHER')) {
      return 'MANUAL_REPLIED_OTHER'
    } else if (value.includes('_NO_REPLY')) {
      return 'MANUAL_NO_REPLY'
    } else if (value.includes('_NOT_ATTENDED')) {
      return 'MANUAL_NOT_ATTENDED'
    } else if (value.includes('_RESCHEDULED')) {
      return 'MANUAL_RESCHEDULED'
    } else if (value.includes('_NO_ANSWER')) {
      return 'MANUAL_NO_ANSWER'
    }
  }

  return (
    <select
      className='form-control form-select wp-status'
      value={formatValue(value)}
      onChange={onChange}
    >
      <option value='MANUAL_REPLIED_CALL'>{wpStatus.CALL}</option>
      <option value='MANUAL_REPLIED_YES'>{wpStatus.YES}</option>
      <option value='MANUAL_REPLIED_NO'>{wpStatus.NO}</option>
      <option
        value='MANUAL_NOT_ATTENDED'
        style={{ display: campaign !== 'confirmation' ? 'none' : '' }}
      >{wpStatus.NOT_ATTENDED}
      </option>
      <option
        value='MANUAL_RESCHEDULED'
        style={{ display: campaign !== 'confirmation' ? 'none' : '' }}
      >{wpStatus.RESCHEDULED}
      </option>
      <option
        value='MANUAL_NO_ANSWER'
        style={{ display: campaign !== 'confirmation' ? 'none' : '' }}
      >{wpStatus.NO_ANSWER}
      </option>
      <option value='MANUAL_REPLIED_OTHER' style={{ display: 'none' }}>{wpStatus.OTHER}</option>
      <option value='MANUAL_NO_REPLY' style={{ display: 'none' }}>{wpStatus.NO_REPLY}</option>
      {campaign === 'confirmation_dr' && (
        <>
          <option
            value='MANUAL_NOT_ATTENDED'
            style={{ display: campaign !== 'confirmation_dr' ? 'none' : '' }}
          >{wpStatus.NOT_ATTENDED}
          </option>
          <option value='MANUAL_REPLIED_PAYMENT' style={{ display: 'none' }}>{wpStatus.PAYMENT}</option>
        </>
      )}
      {campaign === '6 month' && (
        <>
          <option
            value='MANUAL_NO_ANSWER'
            style={{ display: campaign !== '6 month' ? 'none' : '' }}
          >{wpStatus.NO_ANSWER}
          </option>
        </>
      )}
    </select>
  )
}

export default WpStatePicker
