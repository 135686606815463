import React from 'react'

function PasswordInput ({ visiblePassword, togglePasswordVisiblity, handleChange, value, name, text }) {
  return (
    <div className='password-input'>
      <div className='form-floating'>
        <input
          name={name}
          type={visiblePassword ? 'text' : 'password'}
          className='form-control'
          id={name}
          placeholder='Contraseña'
          value={value}
          onChange={handleChange}
          maxLength='20'
          minLength='6'
          autoComplete='current-password'
          required
        />
        <label htmlFor={name}>{text}</label>
      </div>
      {visiblePassword
        ? (
          <i
            className='fas fa-eye-slash password-visibility'
            onClick={togglePasswordVisiblity}
          />
          )
        : (
          <i
            className='far fa-eye password-visibility'
            onClick={togglePasswordVisiblity}
          />
          )}
    </div>
  )
}

export default PasswordInput
