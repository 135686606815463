import dayjs from 'dayjs'

import clients from '../constants/clients'
import { tableRows } from '../constants/tableRows'
import { verifyNumMessagebird } from '../actions/confirmation'

export function capitalizeFirstLetter (str) {
  if (str === undefined) return ''
  const splitStr = str.toLowerCase().trim()
  return splitStr.charAt(0).toUpperCase() + splitStr.slice(1)
}

export function removeAccents (string) {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function cutText (str, shouldCut, strCut) {
  return shouldCut && str.length > strCut ? str.substring(0, strCut) + '... ' : str
}

export function titleCase (str) {
  if (str === undefined) return ''
  const splitStr = str.replace(/\s+/g, ' ').trim().toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export function checkNum (num) {
  return num !== ''
}

export function pickNum (num1 = '', num2 = '') {
  const formattedN1 = num1 !== undefined ? num1.replace(/\D/g, '') : ''
  const formattedN2 = num2 !== undefined ? num2.replace(/\D/g, '') : ''
  const isLocal1 = formattedN1.startsWith('3') && formattedN1.length === 10
  const isLocal2 = formattedN2.startsWith('3') && formattedN2.length === 10

  if ((isLocal1 && isLocal2) || isLocal1) return formattedN1
  if (isLocal2) return formattedN2
  return formattedN1 !== '' ? formattedN1 : formattedN2
}

export async function verifyNum (n, jsonObj) {
  const isLocal = n.startsWith('3') && n.length === 10
  const num = isLocal ? `57${n}` : n
  const res = await verifyNumMessagebird(num)
  const { data } = res
  if (!(data.type).toString().includes('mobile')) {
    if (jsonObj.name1 === undefined || jsonObj.lastName1 === undefined) throw new Error(`El usuario ${jsonObj.name} tiene un número válido, pero no es un número de celular.`)
    throw new Error(`El usuario ${jsonObj.name1} ${jsonObj.lastName1} tiene un número válido, pero no es un número de celular.`)
  }
  return data.phoneNumber
}

export function numToDate (date) {
  const excelDate = Math.round(date).toString()
  if (excelDate.includes('-') || excelDate.includes('/')) return excelDate
  const dateFormat = new Date(Math.round((excelDate - (25569)) * 86400 * 1000))
  return dateFormat.toISOString().split('T')[0]
}

export function strToDate (date) {
  return formatDate(new Date(date), true)
}

export function numToTime (time) {
  const excelTime = time.toString()
  if (excelTime.includes(':')) return excelTime
  const dateFormat = new Date(Math.round((excelTime - (25569)) * 86400 * 1000))
  const timeFromDate = dateFormat.toISOString().split('T')[1].split('.')[0]
  const formattedTime = dayjs('1/1/1 ' + timeFromDate).format('hh:mm a')
  return formattedTime
}

export function strToTime (time) {
  if (time === null) return ''
  if (time.includes(', ')) {
    const timeArr = time.split(', ').map(t => strToTime(t))
    return timeArr.join(', ')
  }
  const splitTime = time.split(':')
  let hours = splitTime[0]
  const minutes = splitTime[1]
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours || 12
  const strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

export function formatTimeToMilitary (time) {
  if (time?.includes(', ')) {
    const timeArr = time.split(', ').map(t => formatTimeToMilitary(t))
    return timeArr.join(', ')
  }
  const militaryTime = time ? dayjs(`1/1/1 ${time}`).format('HH:mm') : null
  return militaryTime
}

export function formatDate (date, toFront = false) {
  let day = date.getDate()
  day = day < 10 ? `0${day}` : day
  let month = date.getMonth() + 1
  month = month < 10 ? `0${month}` : month
  const year = date.getFullYear()
  const dateToBack = year + '-' + month + '-' + day
  const dateToFront = day + '/' + month + '/' + year
  return toFront ? dateToFront : dateToBack
}

export function numberWithCommas (x) {
  return parseInt(x)
    .toString()
    .split(/(?=(?:\d{3})+$)/)
    .join(',')
}

export function getDateRange (start, end) {
  let startDate = start
  let endDate = end
  if (start === undefined || end === undefined) {
    const now = new Date(new Date().toLocaleString('en', { timeZone: 'America/Bogota' }))
    const day = now.getDate() + 1
    const month = now.getMonth() + 1
    const year = now.getFullYear()
    const daysInSpecificMonth = daysInMonth(month, year)
    const d = day < 10 ? '0' + day : day <= daysInSpecificMonth ? day : daysInSpecificMonth
    const m = month < 10 ? `0${month}` : month
    startDate = `${month !== 12 ? year : year - 1}-${m}-01`
    endDate = `${year}-${m}-${d}`
  } else {
    startDate = formatDate(new Date(start))
    endDate = formatDate(new Date(end))
  }

  return startDate + '_' + endDate
}

export function subtractOneMonth (dates) {
  const startDate = new Date(dates.split('_')[0] + 'T05:00:00.000Z')
  const endDate = new Date(dates.split('_')[1] + 'T05:00:00.000Z')
  startDate.setMonth(startDate.getMonth() - 1)
  endDate.setMonth(endDate.getMonth() - 1)
  const formattedStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`
  const formattedEndDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`
  return { formattedStartDate, formattedEndDate }
}

function daysInMonth (month, year) {
  return new Date(year, month, 0).getDate()
}

export function datesAreOnSameDayOrAfter (today, dateToCheck) {
  return (today.getFullYear() === dateToCheck.getFullYear() &&
    today.getMonth() === dateToCheck.getMonth() &&
    today.getDate() === dateToCheck.getDate()) || today < dateToCheck
}

export function formattedKey (key) {
  const keys = Object.freeze({
    nombre: 'firstName',
    apellido: 'lastName',
    telefono: 'number',
    fecha: 'date',
    hora: 'time',
    doctor: 'doctor',
    procedimiento: 'procedure',
    ubicacion: 'location',
    status: 'status'
  })
  return keys[key]
}

export function formattedKeyV2 (key, client, campaign) {
  if (campaign === 'marketing') {
    const keys = Object.freeze({
      firstName: 'nombres',
      lastName: 'apellidos',
      number: 'teléfono',
      userId: 'id',
      date: 'fecha',
      status: 'estado',
      govtId: 'identificación'
    })
    return keys[key]
  } else {
    let doctorName = 'doctor'
    if (client._id === clients.PAULINA_ZARRABE || client._id === clients.CASA_DE_SONRISAS) {
      doctorName = 'odontólogo'
    } else if (client._id === clients.WAKEUP || client._id === clients.DERMATOLOGICA) {
      doctorName = 'responsable'
    }
    const keys = Object.freeze({
      name: 'nombre',
      number: 'teléfono',
      userId: 'id',
      date: 'fecha',
      time: 'hora',
      doctor: doctorName,
      specialty: 'especialidad',
      procedure: 'procedimiento',
      category: 'categoría',
      location: 'ubicación',
      guardian: 'acudiente',
      contact: 'contactar',
      status: 'estado',
      lab: 'laboratorio',
      type: 'tipo'
    })
    return keys[key]
  }
}

export function formatHeader (orderedJson, client, campaign) {
  let objKeys = Object.keys(orderedJson[0])
  objKeys = objKeys.filter(key => key !== '_id')
  return objKeys.map((key) => formattedKeyV2(key, client, campaign))
}

export function formatObjKeys (objKeys, client) {
  if (objKeys.includes('')) {
    throw Error('Revisa los titulos de la tabla, faltan campos.')
  }
  let formattedHeader = tableRows.Default
  if (Object.keys(tableRows).includes(client._id)) {
    formattedHeader = tableRows[client._id]
  }
  const aux = objKeys.map(h => {
    const formatted = h.toLowerCase().replace(/(?:\\[rn]|[\r\n])/g, '').trim()
    return formattedHeader[formatted]
  }).filter(x => x !== undefined)

  return aux
}

export function orderJsonByTime (confirmations) {
  return confirmations.sort((a, b) => {
    let timeA = '00:00'
    let timeB = '00:00'
    if (b.time && a.time) {
      timeA = a.time
      timeB = b.time
    }
    const dateA = new Date((a.date).split('T')[0] + 'T' + (timeA) + ':00.000Z')
    const dateB = new Date((b.date).split('T')[0] + 'T' + (timeB) + ':00.000Z')
    return dateA - dateB
  })
}

function parseDMY (s) {
  const [d, m, y] = s.split(/\D/)
  return y + '-' + m + '-' + d
}

export function orderConfirmationsByDate (confirmations) {
  return confirmations.sort((a, b) => {
    let timeA = '00:00'
    let timeB = '00:00'
    if (b.time && a.time) {
      timeA = formatTimeToMilitary(a.time)
      timeB = formatTimeToMilitary(b.time)
    }
    const dateA = new Date(parseDMY(a.date) + 'T' + (timeA) + ':00.000Z')
    const dateB = new Date(parseDMY(a.date) + 'T' + (timeB) + ':00.000Z')
    return dateA - dateB
  })
}

export function formatImport (client) {
  const foundClient = Object.keys(clients).find(key => clients[key] === client._id)
  return foundClient?.toLowerCase() || 'Default'
}

export function sheetFormat (lines, id) {
  if (id === clients.URO_SERVICE && lines[0].includes('Citas para la fecha')) {
    const keys = lines[1].split(';')
    lines.shift()
    lines.shift()
    keys.push('doctor')
    return keys
  } else if (id === clients.WAKEUP && lines[0].includes('AGENDA MEDICA')) {
    const keys = lines[1].split(';').filter(x => x !== '')
    lines.shift()
    lines.shift()
    keys.push('doctor')
    keys.push('ubicacion')
    return keys
  } else if (id === clients.JUAN_PABLO_GONZALEZ && lines[0].split(';').includes('')) {
    const keys = lines[0].split(';').map(x => {
      if (x === '') return 'Num'
      return x
    })
    lines.shift()
    return keys
  }

  return lines.shift().split(';')
}

export function isHistoryMoreThanXDays (dateForDB, campaign) {
  const d = campaign === '6 month' ? 365 : 4
  const today = new Date()
  const todayInMs = today.getTime()
  const tableDate = new Date(dateForDB + 'T05:00:00Z')
  const tableDateInMs = tableDate.getTime()
  const diff = todayInMs - tableDateInMs
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const showSelectors = days < d
  return showSelectors
}

export function splitSheet (text, id) {
  if (id === clients.WAKEUP) {
    const regex = /medio de la recepción\.(.*?)-Para prestarle un mejor servicio/gs
    const validText = text.replace(regex, ' ')

    // Check if the number of ; in a line is the same as the number of ; in the header, if not, join the line with the next one
    const header = validText.split('\n')[0]
    const headerLength = header.split(';').length
    const validTextArr = validText.split('\n')
    const validTextArrLength = validTextArr.length
    const finalArr = [header]
    for (let i = 1; i < validTextArrLength; i++) {
      const line = validTextArr[i]
      if (line === undefined) continue
      const lineLength = line.split(';').length
      if (lineLength === headerLength) {
        finalArr.push(line)
      } else if (lineLength < headerLength) {
        const nextLine = validTextArr[i + 1]
        validTextArr[i] = line + nextLine + '\n'
        finalArr.push(validTextArr[i])
        validTextArr.splice(i + 1, 1)
      }
    }
    return finalArr.filter(x => x.includes(';'))
  }
  return text.split('\n')
}

export function monthToNumber (monthString) {
  const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
    'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
  const month = months.indexOf(monthString.toLowerCase()) + 1
  return month < 10 ? `0${month}` : month
}

export function handleNumberFormat (number) {
  if (number === 0) return '-'
  const splitNumbers = number.toString().split('.')
  splitNumbers[0] = splitNumbers[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return '$' + splitNumbers.join('.')
}
