import { Popover } from 'bootstrap'
import React, { useEffect, useRef } from 'react'

import './UserCounterPopover.css'

function UserCounterPopover ({ message, innerContent }) {
  const setPopoverState = useRef()
  const popover = useRef()

  useEffect(() => {
    let isActive = true
    if (isActive) setPopoverState.current = new Popover(popover.current)
    return function cleanup () {
      isActive = false
    }
  }, [])

  return (
    <div
      ref={popover}
      trigger='hover'
      data-bs-delay='{"show":0, "hide":100}'
      data-bs-trigger='hover'
      data-bs-placement='bottom'
      data-bs-toggle='tooltip'
      data-bs-content={message}
      className='popover-btn'
    >
      {innerContent}
    </div>
  )
}

export default UserCounterPopover
